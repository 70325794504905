import React, { FC } from "react"
import { Container, Content, BoxCards, Card, BoxInfo } from './style'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"
import { structuredData } from '../../pageContext.json'

interface IDicasSimplificar {
  image: string;
  alt: string;
  link: string;
  title: string;
}

const S9_DicasSimplificar: FC = () => {
  const dataPage: IDicasSimplificar [] = structuredData?.session_9

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container>
      <Content>
        <h3>Dicas para simplificar</h3>

        <BoxCards>
          {dataPage?.map((item: IDicasSimplificar) => (
            <a
              key={item?.title}
              href={item?.link}
              title={item?.title}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_09',
                  section_name: 'Dicas para simplificar',
                  element_action: 'click button',
                  element_name: 'Aproveitar',
                  redirect_url: 'Card do blog',
                })
              }}
            >
              <Card>
                <div>
                  <img src={item?.image} alt={item?.alt} />
                </div>
                <h4>{item?.title}</h4>
              </Card>
            </a>
            ))}
        </BoxCards>

        <BoxInfo>
          <p><strong>Qual o melhor plano de celular?</strong></p>

          <p>Pra escolher o melhor plano é importante considerar suas necessidades pessoais, orçamento e preferências.</p>
          <p>Os planos da operadora de celular do Inter atendem a qualquer perfil com opções pra todos bolsos, além de contar com cobertura Vivo, a melhor rede móvel do Brasil.
            De forma geral, a melhor escolha tende a ser o plano de 14GB + 4GB de portabilidade por R$45.
          </p>

          <p><strong>Qual é a diferença entre pré-pago, controle e pós?</strong></p>

          <p>Resumidamente, as diferenças entre os planos pré-pago, controle e pós-pago são as seguintes:</p>

          <p>
            - Pré-pago: os usuários fazem uma recarga prévia de créditos. Não há fatura mensal, o controle dos gastos é total, além de não ter contrato de fidelidade.
          </p>
          <p>
            - Controle: os usuários pagam um valor fixo mensal por um pacote de serviços com uma franquia de minutos, mensagens e dados móveis. Se a franquia for excedida, pode haver cobranças extras ou redução da velocidade da internet.
          </p>
          <p>
            - Pós-pago: os usuários pagam uma fatura mensal com valor fixo e têm acesso a serviços ilimitados, como chamadas, mensagens e dados móveis. Geralmente, são necessários contratos de fidelidade e as faturas são pagas depois da <strong>utilização dos serviços.</strong>
          </p>

          <p><strong>Qual o plano de celular mais barato?</strong></p>

          <p>Por apenas R$15 – menos de R$ 1 por dia – você pode ter um plano com 6GB + 2GB de portabilidade e com todos os benefícios da operadora de celular do Inter.
          </p>
        </BoxInfo>
      </Content>
    </Container>
  )
}

export default S9_DicasSimplificar
