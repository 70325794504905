import React from 'react'
const IconGift = () => {
  return (
    <div>
      <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M19.821 3.52344C18.5152 3.52344 17.3346 4.05975 16.4876 4.92412C15.6406 4.05975 14.4601 3.52344 13.1543 3.52344C10.577 3.52344 8.48763 5.61278 8.48763 8.1901C8.48763 8.90584 8.64876 9.58393 8.93672 10.1901H4.48763C3.75125 10.1901 3.1543 10.7871 3.1543 11.5234V16.8568C3.1543 17.5932 3.75125 18.1901 4.48763 18.1901H5.82096V26.1901C5.82096 28.3992 7.61182 30.1901 9.82096 30.1901H23.1543C25.3634 30.1901 27.1543 28.3992 27.1543 26.1901V18.1901H28.4876C29.224 18.1901 29.821 17.5932 29.821 16.8568V11.5234C29.821 10.7871 29.224 10.1901 28.4876 10.1901H24.0385C24.3265 9.58393 24.4876 8.90584 24.4876 8.1901C24.4876 5.61278 22.3983 3.52344 19.821 3.52344ZM17.821 27.5234H23.1543C23.8907 27.5234 24.4876 26.9265 24.4876 26.1901V18.1901H17.821V27.5234ZM15.1543 18.1901V27.5234H9.82096C9.08458 27.5234 8.48763 26.9265 8.48763 26.1901V18.1901H15.1543ZM17.821 15.5234H27.1543V12.8568H17.821V15.5234ZM15.1543 12.8568V15.5234H5.82096V12.8568H15.1543ZM17.821 10.1901V8.1901C17.821 7.08553 18.7164 6.1901 19.821 6.1901C20.9255 6.1901 21.821 7.08553 21.821 8.1901C21.821 9.29467 20.9255 10.1901 19.821 10.1901H17.821ZM15.1543 10.1901H13.1543C12.0497 10.1901 11.1543 9.29467 11.1543 8.1901C11.1543 7.08553 12.0497 6.1901 13.1543 6.1901C14.2589 6.1901 15.1543 7.08553 15.1543 8.1901V10.1901Z' fill='#161616' />
      </svg>
    </div>
  )
}
export default IconGift
