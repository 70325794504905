import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  background-color: #FDF8EE;

  padding: 44px 21px;
`

export const Content = styled.div``

export const BoxTitle = styled.div`
  text-align: center;
  margin-bottom: 40px;

  h2{
    color: #EA7100;
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    font-family: 'Citrina', Helvetica, sans-serif;
    margin-bottom: 24px;
  }
  p{
    color: #6A6C72;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  @media (min-width: ${breakpoints.md}){
    max-width: 550px;
    margin: auto;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 766px;

    h2{
      font-size: 40px;
      line-height: 44px;
    }
    p{
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  @media (min-width: ${breakpoints.xxl}){
    max-width: 925px;

    h2{
      font-size: 56px;
      line-height: 62px;
    }
    p{
      font-size: 24px;
      line-height: 30px;
    }
  }
`

export const BoxCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const CarouselContent = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}){
    display: block;
  }
`

export const CarouselSpace = styled.div`
  display: none;
  
  @media (min-width: ${breakpoints.md}){
    display: block;
    padding: 0 12px;
  }

  @media (min-width: ${breakpoints.lg}){
    padding: 0 20px;
  }
`

export const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: ${breakpoints.md}){
    display: none;
  }
`
