import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const Container = styled.div`
  background-color: #FFCA96;
  padding: 45px 24px;

  @media (min-width: ${breakpoints.lg}){
    padding: 60px 0;
  }
`

export const Content = styled.div`
  h2{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: center;
    color: #161616;
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.md}){
    margin-bottom: 40px;
    max-width: 576px;
    margin: auto;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 936px;

    h2{
      font-size: 40px;
      line-height: 44px;
      margin-bottom: 40px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    max-width: 1130px;

    h2{
      font-size: 56px;
      line-height: 60px;
      margin-bottom: 55px;
    }
  }
`

export const BoxCard = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  @media (min-width: ${breakpoints.md}){
    .cardItem-0 {
      order: 1;
    }
    .cardItem-1 {
      order: 2;
    }
    .cardItem-2 {
      order: 3;
    }
    .cardItem-3 {
      order: 5;
    }
    .cardItem-4 {
      order: 4;
    }
    .cardItem-5 {
      order: 6;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    grid-template-columns: repeat(3, 1fr);

    .cardItem-0 {
      order: 1;
    }
    .cardItem-1 {
      order: 2;
    }
    .cardItem-2 {
      order: 3;
    }
    .cardItem-3 {
      order: 4;
    }
    .cardItem-4 {
      order: 5;
    }
    .cardItem-5 {
      order: 6;
    }
  }
`

export const Card = styled.div`
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  height: 167px;
  
  > div{
    flex-basis: 50%;
  }

  .card-text{
    display: flex;
    align-items: center;
    padding-left: 25px;

    p{
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #161616
    }
  }

  .card-image{
    position: relative;

    img{
      max-height: 100%;
    }
  }

  &.cardItem-0{
    background-color: #ffffff;

    .card-image{
      img{
        position: absolute;
        bottom: -20px;
        height: 165px;
      }
    }
  }
  &.cardItem-1{
    background-color: #FDF8EE;
  }
  &.cardItem-2{
    background-color: #FDF8EE;
    .card-image{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        height: 115px;
      }
    }
  }
  &.cardItem-3{
    background-color: #FDF8EE;
    .card-image{
      img{
        height: 115px;
        bottom: 0;
        position: absolute;
        right: 16px;
      }
    }
  
  }
  &.cardItem-4{
    background-color: #ffffff;
    .card-image{
      img{
        height: 140px;
        bottom: 0;
        position: absolute;
        right: 20px;
      }
    }
  }
  &.cardItem-5{
    background-color: #FDF8EE;

    .card-image{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        height: 100px;
      }
    }
  }

  @media (min-width: ${breakpoints.md}){
    height: 225px;

    .card-text{
      padding-left: 40px;

      p{
        font-size: 20px;
        line-height: 24px;
      }
    }

    &.cardItem-0{
      flex-direction: row-reverse;
      .card-text{
        p{
          width: 200px;
        }
      }
      .card-image{
        img{
          height: 217px;
          left: 20px;
          bottom: -10px;
        }
      }
    }
    &.cardItem-1{
      .card-text{
        p{
          width: 200px;
        }
      }
    }
    &.cardItem-2{
      .card-text{
        p{
          width: 200px
        }
      }
      .card-image{
        img{
          height: 170px;
        }
      }
    }
    &.cardItem-3{
      .card-text{
        p{
          width: 200px;
        }
      }
      .card-image{
        img{
          height: 180px;
        }
      }
    }
    &.cardItem-4{
      flex-direction: row-reverse;
      .card-text{
        p{
          width: 200px
        }
      }
      .card-image{
        img{
          height: 195px;
          left: 40px;
        }
      }
    }
    &.cardItem-5{
      .card-text{
        p{
          width: 200px;
        }
      }
      .card-image{
        img{
          height: 120px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.lg}){
    height: 347px;

    > div:nth-of-type(1){
      flex-basis: 35%;
    }
    > div:nth-of-type(2){
      flex-basis: 65%;
    }

    &.cardItem-0{
      background-color: #FDF8EE;
      flex-direction: column;

      .card-text{
        padding: 0 0 0 25px;
        width: 100%;
      }
      .card-image{
        display: flex;
        justify-content: center;

        img{
          height: 270px;
          left: auto;
          bottom: 0;
        }
      }
    }
    &.cardItem-1{
      background-color: #FFFFFF;
      flex-direction: column;

      .card-text{
        padding: 0 0 0 25px;
        width: 100%;
      }
      .card-image{
        display: flex;
        justify-content: center;

        img{
          height: 220px;
        }
      }
    }
    &.cardItem-2{
      flex-direction: column;

      .card-text{
        padding: 0 0 0 25px;
        width: 100%;
      }
      .card-image{
        display: flex;
        justify-content: center;

        img{
          height: 190px;
        }
      }
    }
    &.cardItem-3{
      flex-direction: column;

      .card-text{
        padding: 0 0 0 25px;
        width: 100%;
      }
      .card-image{
        display: flex;
        justify-content: center;

        img{
          height: 200px;
          left: auto;
          right: auto;
          bottom: 0;
        }
      }
    }
    &.cardItem-4{
      flex-direction: column;

      .card-text{
        padding: 0 0 0 25px;
        width: 100%;
      }
      .card-image{
        display: flex;
        justify-content: center;

        img{
          height: 240px;
          left: auto;
          bottom: 0;
        }
      }
    }
    &.cardItem-5{
      flex-direction: column;

      .card-text{
        padding: 0 0 0 25px;
        width: 100%;
      }
      .card-image{
        display: flex;
        justify-content: center;

        img{
          height: 160px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.xl}){
    height: 418px;

    .card-text{
      p{
        font-size: 24px;
        line-height: 30px;
      }
    }

    &.cardItem-1{
      .card-image{
        img{
          height: 270px;
        }
      }
    }
    &.cardItem-2{
      .card-image{
        img{
          height: 250px;
        }
      }
    }
    &.cardItem-3{
      .card-image{
        img{
          height: 100%;
        }
      }
    }
    &.cardItem-4{
      .card-image{
        img{
          height: 100%;
        }
      }
    }
  }
`
