import React from 'react'

const IconTelegram = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12 2.05835C9.34784 2.05835 6.8043 3.11192 4.92893 4.98728C3.05357 6.86265 2 9.40618 2 12.0583C2 14.7105 3.05357 17.2541 4.92893 19.1294C6.8043 21.0048 9.34784 22.0583 12 22.0583C14.6522 22.0583 17.1957 21.0048 19.0711 19.1294C20.9464 17.2541 22 14.7105 22 12.0583C22 9.40618 20.9464 6.86265 19.0711 4.98728C17.1957 3.11192 14.6522 2.05835 12 2.05835Z' fill='#29B6F6' />
        <path d='M16.975 7.55835L15.102 17.1214C15.102 17.1214 15.0215 17.5584 14.4795 17.5584C14.1915 17.5584 14.043 17.4214 14.043 17.4214L9.986 14.0549L8.001 13.0544L5.4535 12.3769C5.4535 12.3769 5 12.2459 5 11.8709C5 11.5584 5.4665 11.4094 5.4665 11.4094L16.1245 7.17535C16.124 7.17485 16.45 7.05785 16.6875 7.05835C16.8335 7.05835 17 7.12085 17 7.30835C17 7.43335 16.975 7.55835 16.975 7.55835Z' fill='white' />
        <path d='M11.5004 15.3108L9.78741 16.9978C9.78741 16.9978 9.71291 17.0553 9.61341 17.0578C9.57891 17.0588 9.54191 17.0533 9.50391 17.0363L9.98591 14.0538L11.5004 15.3108Z' fill='#B0BEC5' />
        <path d='M14.9485 9.1563C14.864 9.0463 14.708 9.0263 14.598 9.1098L8 13.0583C8 13.0583 9.053 16.0043 9.2135 16.5143C9.3745 17.0248 9.5035 17.0368 9.5035 17.0368L9.9855 14.0543L14.9015 9.5063C15.0115 9.4228 15.032 9.2663 14.9485 9.1563Z' fill='#CFD8DC' />
      </svg>
    </div>
  )
}
export default IconTelegram
