import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  background-color: #FDF8EE;
  padding: 45px 0;
  @media (min-width: ${breakpoints.md}){
    padding: 40px 0;
  }

  @media (min-width: ${breakpoints.lg}){
    padding: 65px 0;
  }

  @media (min-width: ${breakpoints.xxl}){
    padding: 110px 0;
  }
`

export const Content = styled.div`
  max-width: 300px;
  margin: auto;

  display: flex;
  flex-direction: column;
  
  gap: 30px;
  @media (min-width: ${breakpoints.md}){
    flex-direction: row-reverse;
    max-width: 715px;
    align-items: center;
    gap: 30px;
  }

  @media (min-width: ${breakpoints.lg}){
    max-width: 945px;
    gap: 60px;
  }

  @media (min-width: ${breakpoints.xxl}){
    max-width: 1130px;
    gap: 40px;
  }
`

export const BoxImage = styled.div`
  img{
    width: 100%;
  }

  @media (min-width: ${breakpoints.md}){
    flex-basis: 50%;
  }

  @media (min-width: ${breakpoints.lg}){
    flex-basis: 60%;
    text-align: center;
    img{
      max-width: 460px;
    }
  }

  @media (min-width: ${breakpoints.xxl}){
    flex-basis: 45%;
    img{
      max-width: 640px;
    }
  }
`

export const BoxContent = styled.div`
  h1{
    font-weight: 700;
    font-family: 'Citrina', Helvetica, sans-serif !important;
    color: #161616;

    font-size: 24px;
    line-height: 26.4px;
    margin-bottom: 16px;

    span{
      color: #EA7100
    }
  }
  p{
    font-weight: 400;
    font-family: 'inter', Helvetica, sans-serif;
    color: #6A6C72;

    font-size: 16px;
    line-height: 19.36px;

    margin-bottom: 16px
  }
  a{
    margin-top: 40px;
    font-weight: 700;
    font-family: 'inter', Helvetica, sans-serif;
    color: #FFFFFF;

    font-size: 14px;
    line-height: 16.94px;

    text-transform: capitalize;
    height: 48px;
  }

  @media (min-width: ${breakpoints.md}){
    flex-basis: 50%;

    h1{
      font-size: 28px;
      line-height: 30.8px;
    }
    p{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    flex-basis: 40%;

    h1{
      font-size: 40px;
      line-height: 44px;
    }
    p{
      font-size: 18px;
      line-height: 21px;
    }
  }

  @media (min-width: ${breakpoints.xxl}){
    flex-basis: 55%;

    h1{
      font-size: 48px;
      line-height: 52.8px;
    }
  }
`
