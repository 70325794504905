import React from 'react'

const IconPinterest = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12 22.0583C17.5228 22.0583 22 17.5812 22 12.0583C22 6.5355 17.5228 2.05835 12 2.05835C6.47715 2.05835 2 6.5355 2 12.0583C2 17.5812 6.47715 22.0583 12 22.0583Z' fill='#E60023' />
        <path d='M12.221 5.76636C7.90482 5.76636 5.61328 8.66367 5.61328 11.8179C5.61328 13.2848 6.39404 15.1106 7.64328 15.6921C7.83249 15.7802 7.93405 15.7421 7.97751 15.5587C8.01096 15.4194 8.17941 14.7402 8.25519 14.4244C8.27942 14.3237 8.26752 14.2371 8.18597 14.1379C7.77249 13.6364 7.44174 12.7148 7.44174 11.8556C7.44174 9.64981 9.11172 7.51559 11.9567 7.51559C14.4133 7.51559 16.1333 9.18982 16.1333 11.584C16.1333 14.289 14.7671 16.1633 12.9898 16.1633C12.0083 16.1633 11.2733 15.3513 11.509 14.3556C11.7917 13.1671 12.3375 11.8848 12.3375 11.0264C12.3375 10.2587 11.926 9.61789 11.0725 9.61789C10.069 9.61789 9.26328 10.6556 9.26328 12.0464C9.26328 12.9314 9.5625 13.5306 9.5625 13.5306C9.5625 13.5306 8.57135 17.7213 8.3898 18.5013C8.18881 19.3625 8.26715 20.5721 8.35413 21.363C8.57968 21.4515 8.80533 21.5399 9.03851 21.6121C9.44696 20.9482 10.056 19.8593 10.2817 18.991C10.4037 18.5229 10.9052 16.6136 10.9052 16.6136C11.231 17.2356 12.1833 17.7621 13.1967 17.7621C16.2125 17.7621 18.386 14.9886 18.386 11.5421C18.386 8.23788 15.6902 5.76636 12.221 5.76636Z' fill='white' />
      </svg>
    </div>
  )
}
export default IconPinterest
