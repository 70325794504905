import React from 'react'
const IconFork = () => {
  return (
    <div>
      <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M4.48763 3.52344C5.22401 3.52344 5.82096 4.12039 5.82096 4.85677V10.9712C5.82096 11.8249 6.1601 12.6436 6.76377 13.2473C7.86754 14.3511 8.48763 15.8481 8.48763 17.4091V26.1901C8.48763 26.9265 9.08458 27.5234 9.82096 27.5234C10.5573 27.5234 11.1543 26.9265 11.1543 26.1901V17.4091C11.1543 15.8481 11.7744 14.3511 12.8782 13.2473C13.4818 12.6436 13.821 11.8249 13.821 10.9712V4.85677C13.821 4.12039 14.4179 3.52344 15.1543 3.52344C15.8907 3.52344 16.4876 4.12039 16.4876 4.85677V10.9712C16.4876 12.5321 15.8675 14.0291 14.7638 15.1329C14.1601 15.7366 13.821 16.5553 13.821 17.4091V26.1901C13.821 28.3992 12.0301 30.1901 9.82096 30.1901C7.61182 30.1901 5.82096 28.3992 5.82096 26.1901V17.4091C5.82096 16.5553 5.48183 15.7366 4.87815 15.1329C3.77439 14.0291 3.1543 12.5321 3.1543 10.9712V4.85677C3.1543 4.12039 3.75125 3.52344 4.48763 3.52344Z' fill='#161616' />
        <path fillRule='evenodd' clipRule='evenodd' d='M19.1543 4.85677C19.1543 4.12039 19.7513 3.52344 20.4876 3.52344H21.821C26.2392 3.52344 29.821 7.10516 29.821 11.5234V20.8568C29.821 21.5932 29.224 22.1901 28.4876 22.1901H27.1543V26.1901C27.1543 28.3992 25.3634 30.1901 23.1543 30.1901C20.9452 30.1901 19.1543 28.3992 19.1543 26.1901V4.85677ZM23.1543 27.5234C23.8907 27.5234 24.4876 26.9265 24.4876 26.1901V20.8568C24.4876 20.1204 25.0846 19.5234 25.821 19.5234H27.1543V11.5234C27.1543 8.57792 24.7665 6.1901 21.821 6.1901V26.1901C21.821 26.9265 22.4179 27.5234 23.1543 27.5234Z' fill='#161616' />
        <path d='M11.1543 4.85677C11.1543 4.12039 10.5573 3.52344 9.82096 3.52344C9.08458 3.52344 8.48763 4.12039 8.48763 4.85677V10.1901C8.48763 10.9265 9.08458 11.5234 9.82096 11.5234C10.5573 11.5234 11.1543 10.9265 11.1543 10.1901V4.85677Z' fill='#161616' />
      </svg>
    </div>
  )
}
export default IconFork
