import React, { FC, useState } from "react"
import { BoxDesktop, BoxImage, BoxInfo, BoxMobile, Container, ContentDesktop, ContentMobile } from './style'
import DefaultCarousel from "src/components/UI/Carousels/DefaultCarousel"
import { structuredData } from '../../pageContext.json'

interface IPortabilidade {
  description: string;
  imageDesktop: string;
  imageMobile: string;
}

const S6_PortabilidadeInterCel: FC = () => {
  const dataPage: IPortabilidade [] = structuredData?.session_6

  const [ imageView, setImageView ] = useState(dataPage[0].imageDesktop)

  return (
    <Container>
      <ContentMobile>
        <h3>Como fazer <br />portabilidade?</h3>
        <DefaultCarousel
          sm={{ items: 1 }}
          notShowArrows
          autoPlay
        >
          {dataPage?.map((item: IPortabilidade) => (
            <BoxMobile key={item?.description}>
              <p dangerouslySetInnerHTML={{ __html: item?.description }} />
              <div>
                <img src={item?.imageMobile} alt='Tela de celular mostrando o Super App Inter.' />
              </div>
            </BoxMobile>
          ))}

        </DefaultCarousel>
      </ContentMobile>

      <ContentDesktop>
        <BoxDesktop>
          <BoxInfo>
            <h3>Como fazer portabilidade?</h3>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(index: number) => {
                setImageView(dataPage[index].imageDesktop)
              }}
            >
              {dataPage?.map((item: IPortabilidade) => (
                <div key={item?.description}>
                  <p dangerouslySetInnerHTML={{ __html: item?.description }} />
                </div>
              ))}
            </DefaultCarousel>

            <a
              href='#session-4'
              title='Fazer portabilidade'
              className='btn btn-orange--extra buttonMD'
            >
              Fazer portabilidade
            </a>
          </BoxInfo>
          <BoxImage>
            <img src={imageView} alt='Tela de celular mostrando o Super App Inter.' />
          </BoxImage>
        </BoxDesktop>

      </ContentDesktop>
    </Container>
  )
}

export default S6_PortabilidadeInterCel
