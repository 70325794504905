import React, { FC } from 'react'
import { BoxImage, BoxText, Container, Content } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const S3_CoberturaVivo: FC = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container>
      <Content>
        <BoxImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra3-vivo/image.webp'
            alt='Logo Vivo'
          />
        </BoxImage>
        <BoxText>
          <h2>
            Conte com a Cobertura Vivo.
          </h2>
          <p>
            A <strong>operadora de celular do Inter</strong> usa a infraestrutura de
            <strong> cobertura Vivo</strong>, a melhor rede móvel do Brasil,
            premiada como “Best in Test” pela empresa independente umlaut.
          </p>
          <a
            href='https://mapadecobertura.vivo.com.br/'
            title='Visitar o site da Vivo'
            target='_blank'
            rel='noreferrer'
            className='btn btn-orange--extra buttonMD'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_03',
                section_name: 'Conte com a Cobertura Vivo.',
                element_action: 'click button',
                element_name: 'Consultar cobertura Vivo',
                redirect_url: 'https://mapadecobertura.vivo.com.br/',
              })
            }}
          >
            Consultar Cobertura Vivo
          </a>
        </BoxText>
      </Content>
    </Container>
  )
}

export default S3_CoberturaVivo
