import React, { FC } from 'react'

import { Container, Content, BoxImage, BoxContent } from './style'

const S1_Hero: FC = () => {
  return (
    <Container>
      <Content>
        <BoxImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-hero/image.webp'
            alt='Mulher e homem jovens sorrindo olhando para o celular ao ar livre.'
          />
        </BoxImage>
        <BoxContent>
          <h1>Planos de celular a partir de R$15 e sem fidelidade.<br />Seja <span>Inter Cel</span></h1>
          <p>Aproveite <strong>planos econômicos e com GBs de internet</strong> pra navegar no Super App do Inter, usar <strong>WhatsApp</strong>, <strong>TikTok</strong> e mais apps sem gastar seus dados.</p>
          <p>E mais: ligações ilimitadas, bônus e cashback a cada renovação.</p>
          <a
            href='#session-4'
            title='Mostrar Planos'
            className='btn btn-orange--extra buttonMD'
          >
            Mostrar planos
          </a>
        </BoxContent>
      </Content>
    </Container>
  )
}

export default S1_Hero
