import React, { FC } from "react"
import { Container, Content, BoxCard, Card } from "./styles"

interface ISempreConectado {
  text: string;
  alt: string;
  img: string;
}

const data: ISempreConectado[] = [
  {
    text: 'Ligações e apps ilimitados',
    alt: 'Celular com o app do Inter mostrando a tela de inter cel',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra5-celular/image.webp',
  },
  {
    text: 'Planos de celular sem fidelidade',
    alt: 'Dois chips de celular na cor laranja.',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra5-chip/image.webp',
  },
  {
    text: 'Planos de internet para redes sociais',
    alt: 'Planos de internet para redes sociais',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra5-redes/image.webp',
  },
  {
    text: 'Cashback a cada renovação',
    alt: 'Cashback a cada renovação',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra5-cashback/image.webp',
  },
  {
    text: 'Bônus de portabilidade',
    alt: 'Mulher com o celular no ouvido olhando para o alto com leve sorriso no rosto',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra5-app/image.webp',
  },
  {
    text: 'Chip de celular virtual (eSIM)',
    alt: 'Ilustração de um chip virtal eSIM',
    img: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-cel-dobra5-esim/image.webp',
  },
]

const S5_SempreConectado: FC = () => {
  return (
    <Container>
      <Content>
        <h2>Fique sempre conectado</h2>
        <BoxCard>
          {data?.map((cardItem: ISempreConectado, index: number) => (
            <Card className={`cardItem-${index}`} key={cardItem?.text}>
              <div className='card-text'>
                <p>{cardItem?.text}</p>
              </div>
              <div className='card-image'>
                <img
                  src={cardItem?.img}
                  alt={cardItem?.alt}
                />
              </div>
            </Card>
          ))}
        </BoxCard>
      </Content>
    </Container>
  )
}

export default S5_SempreConectado
