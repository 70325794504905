import React from 'react'

const IconTiktok = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M5.452 3.05835H18.5475C19.902 3.05835 21 4.15635 21 5.51035V18.6059C21 19.9604 19.902 21.0583 18.548 21.0583H5.452C4.098 21.0583 3 19.9604 3 18.6063V5.51035C3 4.15635 4.098 3.05835 5.452 3.05835Z' fill='#212121' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.6041 10.3618C15.3921 10.9248 16.3576 11.2558 17.4001 11.2558V9.25034C17.2026 9.25034 17.0061 9.22984 16.8131 9.18884V10.7673C15.7706 10.7673 14.8056 10.4358 14.0171 9.87334V13.9653C14.0171 16.0123 12.3566 17.6718 10.3086 17.6718C9.54464 17.6718 8.83414 17.4408 8.24414 17.0448C8.91764 17.7328 9.85664 18.1598 10.8956 18.1598C12.9436 18.1598 14.6041 16.5003 14.6041 14.4533V10.3618ZM15.3286 8.33884C14.9261 7.89934 14.6616 7.33084 14.6041 6.70234V6.44434H14.0476C14.1876 7.24284 14.6656 7.92534 15.3286 8.33884ZM9.53964 15.4743C9.31464 15.1793 9.19314 14.8188 9.19364 14.4478C9.19364 13.5113 9.95314 12.7523 10.8901 12.7523C11.0646 12.7523 11.2381 12.7788 11.4046 12.8318V10.7818C11.2101 10.7553 11.0141 10.7438 10.8176 10.7478V12.3433C10.6511 12.2903 10.4776 12.2638 10.3026 12.2638C9.36564 12.2638 8.60614 13.0228 8.60614 13.9593C8.60664 14.6218 8.98614 15.1953 9.53964 15.4743Z' fill='#EC407A' />
        <path fillRule='evenodd' clipRule='evenodd' d='M14.0165 9.87334C14.8045 10.4363 15.77 10.7673 16.8125 10.7673V9.18884C16.2305 9.06484 15.7155 8.76084 15.328 8.33834C14.665 7.92484 14.1875 7.24284 14.0475 6.44434H12.586V14.4533C12.5825 15.3868 11.8245 16.1428 10.8895 16.1428C10.3385 16.1428 9.849 15.8803 9.539 15.4738C8.9855 15.1948 8.606 14.6213 8.606 13.9593C8.606 13.0228 9.3655 12.2638 10.3025 12.2638C10.482 12.2638 10.655 12.2918 10.8175 12.3433V10.7483C8.8055 10.7898 7.1875 12.4328 7.1875 14.4538C7.1875 15.4628 7.5905 16.3773 8.2445 17.0453C8.8345 17.4413 9.545 17.6723 10.309 17.6723C12.357 17.6723 14.0175 16.0128 14.0175 13.9658L14.0165 9.87334Z' fill='white' />
        <path fillRule='evenodd' clipRule='evenodd' d='M16.8126 9.18931V8.76231C16.2876 8.76331 15.7736 8.61631 15.3281 8.33831C15.7221 8.76981 16.2411 9.06731 16.8126 9.18931ZM14.0471 6.44431C14.0336 6.36781 14.0236 6.29131 14.0166 6.21381V5.95581H11.9986V13.9653C11.9956 14.8988 11.2371 15.6548 10.3021 15.6548C10.0276 15.6548 9.76861 15.5898 9.53911 15.4738C9.84911 15.8803 10.3386 16.1428 10.8896 16.1428C11.8246 16.1428 12.5826 15.3868 12.5861 14.4533V6.44431H14.0471ZM10.8171 10.7483V10.2938C10.6486 10.2708 10.4786 10.2593 10.3081 10.2593C8.25961 10.2593 6.59961 11.9188 6.59961 13.9658C6.59961 15.2493 7.25211 16.3803 8.24361 17.0453C7.58961 16.3773 7.18661 15.4628 7.18661 14.4538C7.18661 12.4328 8.80511 10.7898 10.8171 10.7483Z' fill='#81D4FA' />
      </svg>
    </div>
  )
}
export default IconTiktok
