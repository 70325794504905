import React from 'react'
const IconPlane = () => {
  return (
    <div>
      <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M7.64751 5.20743C8.54854 4.71596 9.62394 4.74429 10.4975 5.27387L19.5063 10.3101L27.6644 6.90424C29.5188 6.11955 31.5488 7.10537 32.2403 8.93416C32.9366 10.7757 32.1218 12.9341 30.272 13.7271L8.01524 23.2685C6.85987 23.785 5.53351 23.4981 4.67481 22.5897L1.34092 19.0629C0.204068 17.8603 0.204068 15.9399 1.34092 14.7373C1.84431 14.2047 2.51498 13.8034 3.361 13.8034C4.13944 13.8034 4.83684 14.1474 5.42806 14.5582L5.44082 14.5671L7.24276 15.8506C7.58619 16.0952 7.99249 16.1347 8.35337 15.9798L10.6547 14.6817L6.14195 10.068C6.13189 10.0577 6.12199 10.0473 6.11226 10.0367C5.13214 8.96744 5.13214 7.27796 6.11226 6.20874C6.30852 5.99463 6.53937 5.81187 6.79796 5.67082L7.64751 5.20743ZM8.92445 7.54849C8.96112 7.52848 8.99299 7.52287 9.01963 7.52368C9.04635 7.52449 9.07968 7.53214 9.11795 7.556C9.13594 7.56721 9.15419 7.57799 9.17268 7.58833L18.6546 12.889C19.0828 13.1284 19.5985 13.1612 20.0572 12.9698L28.7036 9.3601C29.0655 9.20697 29.5509 9.36154 29.746 9.87732C29.9715 10.4737 29.672 11.0829 29.2213 11.2761L6.95373 20.8222L6.92854 20.8333C6.87494 20.8576 6.82274 20.862 6.77338 20.8519C6.72355 20.8418 6.66629 20.8145 6.61267 20.7578L3.27878 17.231C3.11367 17.0564 3.11367 16.7438 3.27878 16.5691C3.32815 16.5169 3.36348 16.4885 3.38388 16.474C3.44016 16.4864 3.60128 16.5369 3.90054 16.7441L5.69576 18.0227C6.82282 18.8254 8.26894 18.9669 9.52466 18.3765C9.55446 18.3625 9.58373 18.3474 9.6124 18.3312L13.1079 16.3596C13.91 15.9072 14.133 14.9333 13.7835 14.196C13.7067 14.0339 13.6024 13.8817 13.4718 13.7482L8.07307 8.22869C8.06082 8.21208 8.04384 8.17722 8.04384 8.1227C8.04384 8.06034 8.06606 8.02371 8.078 8.01067L8.07896 8.00966L8.92445 7.54849Z' fill='#161616' />
        <path d='M8.48828 26.1903C7.7519 26.1903 7.15495 26.7872 7.15495 27.5236C7.15495 28.26 7.7519 28.8569 8.48828 28.8569H24.4883C25.2247 28.8569 25.8216 28.26 25.8216 27.5236C25.8216 26.7872 25.2247 26.1903 24.4883 26.1903H8.48828Z' fill='#161616' />
      </svg>
    </div>
  )
}
export default IconPlane
