import React from 'react'

const IconClose = (props) => {
  return (
    <div {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M11.0555 12.4702L5.2925 6.7072C4.90198 6.31668 4.90198 5.68351 5.2925 5.29299C5.68303 4.90247 6.31619 4.90247 6.70672 5.29299L12.4697 11.056C12.8602 11.4465 12.8602 12.0797 12.4697 12.4702C12.0792 12.8607 11.446 12.8607 11.0555 12.4702ZM17.2925 18.7072L13.8197 15.2344C13.4292 14.8439 13.4292 14.2107 13.8197 13.8202C14.2102 13.4297 14.8434 13.4297 15.2339 13.8202L18.7067 17.293C19.0972 17.6835 19.0972 18.3167 18.7067 18.7072C18.3162 19.0977 17.683 19.0977 17.2925 18.7072Z' fill='#EA7100' />
        <path fillRule='evenodd' clipRule='evenodd' d='M10.7727 12.753L5.00966 6.99005C4.46293 6.44331 4.46293 5.55688 5.00966 5.01015C5.55639 4.46341 6.44282 4.46341 6.98956 5.01015L12.7526 10.7731C13.2993 11.3199 13.2993 12.2063 12.7526 12.753C12.2058 13.2998 11.3194 13.2998 10.7727 12.753ZM17.0097 18.99L13.5369 15.5173C12.9901 14.9705 12.9901 14.0841 13.5369 13.5374C14.0836 12.9906 14.97 12.9906 15.5168 13.5374L18.9896 17.0101C19.5363 17.5569 19.5363 18.4433 18.9896 18.99C18.4428 19.5368 17.5564 19.5368 17.0097 18.99ZM5.2925 6.7072L11.0555 12.4702C11.446 12.8607 12.0792 12.8607 12.4697 12.4702C12.8602 12.0797 12.8602 11.4465 12.4697 11.056L6.70672 5.29299C6.31619 4.90247 5.68303 4.90247 5.2925 5.29299C4.90198 5.68351 4.90198 6.31668 5.2925 6.7072ZM13.8197 15.2344L17.2925 18.7072C17.683 19.0977 18.3162 19.0977 18.7067 18.7072C19.0972 18.3167 19.0972 17.6835 18.7067 17.293L15.2339 13.8202C14.8434 13.4297 14.2102 13.4297 13.8197 13.8202C13.4292 14.2107 13.4292 14.8439 13.8197 15.2344Z' fill='#EA7100' />
        <path d='M6.70672 18.7072L18.7067 6.7072C19.0972 6.31668 19.0972 5.68351 18.7067 5.29299C18.3162 4.90247 17.683 4.90247 17.2925 5.29299L5.2925 17.293C4.90198 17.6835 4.90198 18.3167 5.2925 18.7072C5.68303 19.0977 6.31619 19.0977 6.70672 18.7072Z' fill='#EA7100' />
        <path fillRule='evenodd' clipRule='evenodd' d='M18.9896 6.99005L6.98956 18.99C6.44282 19.5368 5.55639 19.5368 5.00966 18.99C4.46293 18.4433 4.46293 17.5569 5.00966 17.0101L17.0097 5.01015C17.5564 4.46341 18.4428 4.46341 18.9896 5.01015C19.5363 5.55688 19.5363 6.44331 18.9896 6.99005ZM18.7067 6.7072L6.70672 18.7072C6.31619 19.0977 5.68303 19.0977 5.2925 18.7072C4.90198 18.3167 4.90198 17.6835 5.2925 17.293L17.2925 5.29299C17.683 4.90247 18.3162 4.90247 18.7067 5.29299C19.0972 5.68351 19.0972 6.31668 18.7067 6.7072Z' fill='#EA7100' />
      </svg>
    </div>
  )
}
export default IconClose
