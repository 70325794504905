import React from 'react'
const IconSmartphone = () => {
  return (
    <div>
      <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M15.1556 23.1889C14.4192 23.1889 13.8223 23.7858 13.8223 24.5222C13.8223 25.2586 14.4192 25.8555 15.1556 25.8555H17.8223C18.5586 25.8555 19.1556 25.2586 19.1556 24.5222C19.1556 23.7858 18.5586 23.1889 17.8223 23.1889H15.1556Z' fill='#161616' />
        <path fillRule='evenodd' clipRule='evenodd' d='M9.82227 1.85553C7.61313 1.85553 5.82227 3.64639 5.82227 5.85553V27.1889C5.82227 29.398 7.61313 31.1889 9.82227 31.1889H23.1556C25.3647 31.1889 27.1556 29.398 27.1556 27.1889V5.85553C27.1556 3.64639 25.3647 1.85553 23.1556 1.85553H9.82227ZM8.48893 5.85553C8.48893 5.11915 9.08589 4.5222 9.82227 4.5222H23.1556C23.892 4.5222 24.4889 5.11915 24.4889 5.85553V27.1889C24.4889 27.9252 23.892 28.5222 23.1556 28.5222H9.82227C9.08589 28.5222 8.48893 27.9252 8.48893 27.1889V5.85553Z' fill='#161616' />
      </svg>
    </div>
  )
}
export default IconSmartphone
