import React from 'react'

const IconInstagram = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M17.0076 21.0533L7.00764 21.0628C4.80764 21.0648 3.00614 19.2668 3.00364 17.0668L2.99414 7.06685C2.99214 4.86685 4.79014 3.06535 6.99014 3.06285L16.9901 3.05335C19.1901 3.05135 20.9916 4.84935 20.9941 7.04935L21.0036 17.0493C21.0061 19.2498 19.2076 21.0513 17.0076 21.0533Z' fill='url(#paint0_radial_6507_19669)' />
        <path d='M17.0076 21.0533L7.00764 21.0628C4.80764 21.0648 3.00614 19.2668 3.00364 17.0668L2.99414 7.06685C2.99214 4.86685 4.79014 3.06535 6.99014 3.06285L16.9901 3.05335C19.1901 3.05135 20.9916 4.84935 20.9941 7.04935L21.0036 17.0493C21.0061 19.2498 19.2076 21.0513 17.0076 21.0533Z' fill='url(#paint1_radial_6507_19669)' />
        <path d='M12 15.5583C10.0705 15.5583 8.5 13.9883 8.5 12.0583C8.5 10.1283 10.0705 8.55835 12 8.55835C13.9295 8.55835 15.5 10.1283 15.5 12.0583C15.5 13.9883 13.9295 15.5583 12 15.5583ZM12 9.55835C10.6215 9.55835 9.5 10.6798 9.5 12.0583C9.5 13.4368 10.6215 14.5583 12 14.5583C13.3785 14.5583 14.5 13.4368 14.5 12.0583C14.5 10.6798 13.3785 9.55835 12 9.55835Z' fill='white' />
        <path d='M15.75 9.05835C16.1642 9.05835 16.5 8.72256 16.5 8.30835C16.5 7.89414 16.1642 7.55835 15.75 7.55835C15.3358 7.55835 15 7.89414 15 8.30835C15 8.72256 15.3358 9.05835 15.75 9.05835Z' fill='white' />
        <path d='M15 18.5583H9C7.0705 18.5583 5.5 16.9883 5.5 15.0583V9.05835C5.5 7.12835 7.0705 5.55835 9 5.55835H15C16.9295 5.55835 18.5 7.12835 18.5 9.05835V15.0583C18.5 16.9883 16.9295 18.5583 15 18.5583ZM9 6.55835C7.6215 6.55835 6.5 7.67985 6.5 9.05835V15.0583C6.5 16.4368 7.6215 17.5583 9 17.5583H15C16.3785 17.5583 17.5 16.4368 17.5 15.0583V9.05835C17.5 7.67985 16.3785 6.55835 15 6.55835H9Z' fill='white' />
        <defs>
          <radialGradient id='paint0_radial_6507_19669' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(9.68914 21.0758) scale(22.4495)'>
            <stop stopColor='#FFDD55' />
            <stop offset='0.328' stopColor='#FF543F' />
            <stop offset='0.348' stopColor='#FC5245' />
            <stop offset='0.504' stopColor='#E64771' />
            <stop offset='0.643' stopColor='#D53E91' />
            <stop offset='0.761' stopColor='#CC39A4' />
            <stop offset='0.841' stopColor='#C837AB' />
          </radialGradient>
          <radialGradient id='paint1_radial_6507_19669' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(5.89214 2.8285) scale(14.9065 9.9322)'>
            <stop stopColor='#4168C9' />
            <stop offset='0.999' stopColor='#4168C9' stopOpacity='0' />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
export default IconInstagram
