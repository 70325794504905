import React from 'react'
const IconCar = () => {
  return (
    <div>
      <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M20.4883 2.19067C19.7519 2.19067 19.1549 2.78763 19.1549 3.52401C19.1549 4.26039 19.7519 4.85734 20.4883 4.85734C21.714 4.85734 22.9276 5.09875 24.06 5.5678C25.1924 6.03684 26.2213 6.72433 27.0879 7.59101C27.9546 8.45769 28.6421 9.48659 29.1112 10.619C29.5802 11.7513 29.8216 12.965 29.8216 14.1907C29.8216 14.9271 30.4186 15.524 31.1549 15.524C31.8913 15.524 32.4883 14.9271 32.4883 14.1907C32.4883 12.6148 32.1779 11.0544 31.5748 9.59847C30.9718 8.14257 30.0879 6.8197 28.9736 5.70539C27.8593 4.59109 26.5364 3.70718 25.0805 3.10412C23.6246 2.50106 22.0641 2.19067 20.4883 2.19067Z' fill='#161616' />
        <path d='M20.4883 7.52401C19.7519 7.52401 19.1549 8.12096 19.1549 8.85734C19.1549 9.59372 19.7519 10.1907 20.4883 10.1907C21.0136 10.1907 21.5337 10.2941 22.019 10.4952C22.5043 10.6962 22.9453 10.9908 23.3167 11.3622C23.6881 11.7337 23.9828 12.1746 24.1838 12.6599C24.3848 13.1452 24.4883 13.6654 24.4883 14.1907C24.4883 14.9271 25.0852 15.524 25.8216 15.524C26.558 15.524 27.1549 14.9271 27.1549 14.1907C27.1549 13.3152 26.9825 12.4483 26.6475 11.6395C26.3124 10.8306 25.8214 10.0957 25.2023 9.47663C24.5833 8.85757 23.8483 8.36651 23.0395 8.03148C22.2307 7.69645 21.3638 7.52401 20.4883 7.52401Z' fill='#161616' />
        <path d='M7.91625 13.644C9.11635 13.326 11.7356 12.8573 14.4883 12.8573C15.2247 12.8573 15.8216 12.2604 15.8216 11.524C15.8216 10.7876 15.2247 10.1907 14.4883 10.1907C11.4833 10.1907 8.63411 10.695 7.2331 11.0663C6.01581 11.389 5.14018 12.3426 4.8057 13.4722L3.80335 16.8573H1.82161C1.08523 16.8573 0.488281 17.4543 0.488281 18.1907C0.488281 18.9271 1.08523 19.524 1.82161 19.524H2.15148L1.85945 19.8791C0.972217 20.9577 0.488281 22.3147 0.488281 23.7136V30.1907C0.488281 30.9271 1.08523 31.524 1.82161 31.524C2.55799 31.524 3.15495 30.9271 3.15495 30.1907V23.7136C3.15495 22.929 3.42656 22.1717 3.91896 21.573L5.6043 19.524H23.3723L25.0576 21.573C25.55 22.1717 25.8216 22.929 25.8216 23.7136V30.1907C25.8216 30.9271 26.4186 31.524 27.1549 31.524C27.8913 31.524 28.4883 30.9271 28.4883 30.1907V23.7136C28.4883 22.3147 28.0043 20.9577 27.1171 19.8791L26.8251 19.524H27.1549C27.8913 19.524 28.4883 18.9271 28.4883 18.1907C28.4883 17.4543 27.8913 16.8573 27.1549 16.8573H6.58447L7.36263 14.2293C7.45887 13.9043 7.68335 13.7057 7.91625 13.644Z' fill='#161616' />
        <path d='M7.15495 22.1907C6.41857 22.1907 5.82161 22.7876 5.82161 23.524C5.82161 24.2604 6.41857 24.8573 7.15495 24.8573H8.48828C9.22466 24.8573 9.82162 24.2604 9.82162 23.524C9.82162 22.7876 9.22466 22.1907 8.48828 22.1907H7.15495Z' fill='#161616' />
        <path d='M20.4883 22.1907C19.7519 22.1907 19.1549 22.7876 19.1549 23.524C19.1549 24.2604 19.7519 24.8573 20.4883 24.8573H21.8216C22.558 24.8573 23.1549 24.2604 23.1549 23.524C23.1549 22.7876 22.558 22.1907 21.8216 22.1907H20.4883Z' fill='#161616' />
        <path d='M8.48828 26.8573C7.01552 26.8573 5.82161 28.0513 5.82161 29.524V30.1907C5.82161 30.9271 6.41857 31.524 7.15495 31.524C7.89133 31.524 8.48828 30.9271 8.48828 30.1907V29.524H20.4883V30.1907C20.4883 30.9271 21.0852 31.524 21.8216 31.524C22.558 31.524 23.1549 30.9271 23.1549 30.1907V29.524C23.1549 28.0513 21.961 26.8573 20.4883 26.8573H8.48828Z' fill='#161616' />
        <path d='M20.4883 15.524C21.2247 15.524 21.8216 14.9271 21.8216 14.1907C21.8216 13.4543 21.2247 12.8573 20.4883 12.8573C19.7519 12.8573 19.1549 13.4543 19.1549 14.1907C19.1549 14.9271 19.7519 15.524 20.4883 15.524Z' fill='#161616' />
      </svg>
    </div>
  )
}
export default IconCar
