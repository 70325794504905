import React from 'react'
const IconInter = () => {
  return (
    <div>
      <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.716797' y='0.64502' width='28.8372' height='28.8372' rx='12.8165' fill='white' />
        <path d='M17.0657 15.1917C17.1481 14.673 17.4355 14.4165 17.9279 14.4165C18.4274 14.4165 18.6883 14.7069 18.7357 15.1917H17.0657Z' fill='#FF7A00' />
        <path fillRule='evenodd' clipRule='evenodd' d='M9.52835 6.2522C7.75876 6.2522 6.32422 7.68674 6.32422 9.45633V20.6708C6.32422 22.4404 7.75876 23.8749 9.52835 23.8749H20.7428C22.5124 23.8749 23.947 22.4404 23.947 20.6708V9.45633C23.947 7.68674 22.5124 6.2522 20.7428 6.2522H9.52835ZM9.03542 12.2599H8.85126C8.62525 12.2599 8.44109 12.4263 8.44109 12.6687V12.897C8.44109 13.1395 8.62525 13.3058 8.85126 13.3058H9.03542C9.2684 13.3058 9.44558 13.1395 9.44558 12.897V12.6687C9.44698 12.4249 9.2684 12.2599 9.03542 12.2599ZM12.1228 13.7188C11.548 13.7188 11.1853 14.0232 11.0067 14.3615L10.9788 13.8076H10.1236V17.3765H11.0137V15.3989C11.0137 14.8041 11.329 14.4588 11.8005 14.4588C12.2735 14.4588 12.4646 14.7632 12.4646 15.3101V17.3779H13.3477V15.0818C13.3491 14.2784 13.0004 13.7188 12.1228 13.7188ZM7.92629 14.5419H8.51503V17.3779H9.40512V13.809H7.92629V14.5419ZM17.9363 13.7188C16.8272 13.7188 16.1352 14.4588 16.1352 15.5441V15.6343C16.1352 16.7337 16.8132 17.4667 17.9363 17.4667C18.8473 17.4667 19.4556 16.9959 19.6272 16.2489H18.7441C18.6408 16.5533 18.3883 16.7676 17.9363 16.7676C17.402 16.7676 17.0937 16.442 17.0462 15.8063H19.6481C19.7081 14.5701 19.1194 13.7188 17.9363 13.7188ZM14.8614 16.2841V14.5419H15.792V13.809H14.8614V12.5982H13.9714V16.3743C13.9714 17.0931 14.362 17.4667 15.1837 17.4667C15.3888 17.4667 15.6427 17.4314 15.8073 17.3765V16.6773C15.7111 16.7126 15.5478 16.7535 15.3832 16.7535C14.9912 16.7549 14.8614 16.6097 14.8614 16.2841ZM22.1384 13.747C21.625 13.747 21.2623 14.0514 21.0907 14.5011L21.0697 13.809H20.2145V17.3779H21.1046V15.6075C21.1046 14.9225 21.4129 14.5419 22.091 14.5419C22.1803 14.5419 22.2821 14.549 22.3449 14.556V13.7681C22.2891 13.754 22.2012 13.747 22.1384 13.747Z' fill='#FF7A00' />
      </svg>
    </div>
  )
}
export default IconInter
