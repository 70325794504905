import React from 'react'

const IconTwitter = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M20.9995 6.27285C20.338 6.56585 19.6265 6.76135 18.876 6.85385C19.639 6.40085 20.226 5.67835 20.5015 4.82485C19.7875 5.24335 18.9965 5.55085 18.155 5.71285C17.483 5.00035 16.5245 4.55835 15.4625 4.55835C13.4225 4.55835 11.769 6.19735 11.769 8.21835C11.769 8.50435 11.8025 8.78285 11.8655 9.05335C8.79655 8.89935 6.07455 7.44035 4.25355 5.22635C3.93355 5.76735 3.75355 6.40085 3.75355 7.06935C3.75355 8.33985 4.40405 9.45835 5.39605 10.1173C4.79055 10.0988 4.22055 9.93035 3.72155 9.66035C3.72155 9.67135 3.72155 9.68785 3.72155 9.70335C3.72155 11.4789 4.99505 12.9573 6.68305 13.2938C6.37455 13.3784 6.04855 13.4253 5.71255 13.4253C5.47405 13.4253 5.24155 13.3983 5.01655 13.3579C5.48655 14.8089 6.85005 15.8693 8.46555 15.9008C7.20155 16.8808 5.60955 17.4678 3.87855 17.4678C3.57955 17.4678 3.28705 17.4508 2.99805 17.4158C4.63355 18.4513 6.57555 19.0583 8.66005 19.0583C15.4525 19.0583 19.1685 13.4804 19.1685 8.64135C19.1685 8.48285 19.1635 8.32485 19.156 8.16885C19.881 7.65685 20.506 7.01085 20.9995 6.27285Z' fill='#03A9F4' />
      </svg>
    </div>
  )
}
export default IconTwitter
