import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  padding: 40px 24px;
  background-color: #FFFFFF;

  @media (min-width: ${breakpoints.lg}){
    padding: 50px 0;
  }
`

export const ContentMobile = styled.div`
  display: block;


  h3{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.6px;
    color: #161616;
    margin: 0;
  }

  ul:nth-of-type(1){
    padding-top: 0
  }

  @media (min-width: ${breakpoints.md}){
    display: none;
  }
`

export const BoxMobile = styled.div`
  p{
    font-family: 'inter', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: #161616;

    margin: 15px 0 24px 0
  }

  > div {
    display: flex;
    justify-content: center;

    img {
      width: auto;
      height: 490px;
    }
  }
`

export const ContentDesktop = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}){
    display: block;
  }
`

export const BoxDesktop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 690px;
  margin: auto;
  align-items: center;

  @media (min-width: ${breakpoints.lg}){
    gap: 100px;
    width: 920px;
  }

  @media (min-width: ${breakpoints.xl}){
    gap: 130px;
    width: 1120px;
  }
`

export const BoxInfo = styled.div`
  width: 330px;

  h3{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 33.6px;
    color: #161616;
    margin: 0;
  }

  p{
    font-family: 'inter', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: #161616;

    margin: 15px 0 24px 0
  }

  a{
    display: none;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 460px;

    h3{
      font-size: 48px;
      line-height: 52.8px;
    }

    p{
      font-size: 24px;
      line-height: 29.05px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    a{
      display: block;
      margin-top: 40px;
      max-width: 100%;
      text-transform: none;
    }
  }

`

export const BoxImage = styled.div`
  width: 330px;

  img{
    height: 370px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 360px;

    img{
      height: 400px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    width: 540px;

    img{
      height: 600px;
    }
  }
`
