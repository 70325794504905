import React from 'react'

const IconTinder = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M8.6 10.1518C12.425 8.93732 13.032 5.41632 12.5465 2.25882C12.5465 2.25882 12.5465 2.07682 12.668 2.13732C16.3715 3.95932 20.5 7.78432 20.5 13.6733C20.5 18.0448 17.0395 21.9913 12 21.9913C6.5355 21.9913 3.5 18.1663 3.5 13.6128C3.5 10.8808 5.3215 8.14832 7.4465 6.93432C7.4465 6.93432 7.6285 6.93432 7.6285 7.05582C7.6285 7.66282 7.8715 9.18082 8.539 10.0913L8.6 10.1518Z' fill='url(#paint0_radial_6507_19683)' />
        <defs>
          <radialGradient id='paint0_radial_6507_19683' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(12.195 20.1448) scale(19.3025 19.3025)'>
            <stop stopColor='#FE7356' />
            <stop offset='1' stopColor='#FD297C' />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
export default IconTinder
