import React, { FC } from "react"
import { Container, Content } from "./style"

const S2_BannerPlano: FC = () => {
  return (
    <Container>
      <Content>
        <h2>Seu plano do seu jeito.</h2>
        <p>100% digital, simples e com você no controle de tudo.</p>
      </Content>
    </Container>
  )
}

export default S2_BannerPlano
