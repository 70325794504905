import React, { FC } from "react"
import DefaultCarousel from "src/components/UI/Carousels/DefaultCarousel"
import { Container, BoxCarousel, Card, Content } from "./style"
import useWidth from "src/hooks/window/useWidth"
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"
import { structuredData } from '../../pageContext.json'

interface ICashbackCelular {
  img: string;
  alt: string;
  link: string;
}

const S7_CashbackCelular: FC = () => {
  const dataPage: ICashbackCelular [] = structuredData?.session_7

  const width = useWidth()

  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container>
      <Content>
        <h3>Tem cashback pra você garantir seu novo celular</h3>
        <p>Aproveite pra fazer aquele upgrade.</p>
        <p>No shopping do Inter você encontra as melhoras ofertas e economiza
          <strong> recebendo parte do valor pago pelo seu celular
            novo de volta, direto na sua conta.
          </strong>
        </p>

        <BoxCarousel>
          <DefaultCarousel
            sm={{ items: 2 }}
            md={{ items: 3 }}
            lg={{ items: 4 }}
            xl={{ items: 4 }}
            infinite={width < 1400 ? true : false}
            notShowArrows={width < 768 || width > 1400 ? true : false}
            notShowDots={width < 768 || width > 1400 ? true : false}
          >
            {dataPage?.map((item: ICashbackCelular) => (
              <Card key={item?.alt}>
                <div>
                  <img src={item?.img} alt={item?.alt} />
                </div>
                <a
                  href={item?.link}
                  title='Fazer portabilidade'
                  className='btn btn-orange--extra buttonMD'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Tem cashback pra você garantir seu novo celular',
                      element_action: 'click button',
                      element_name: 'Abrir ofertas',
                      redirect_url: item?.link,
                    })
                  }}
                >
                  Abrir ofertas
                </a>
              </Card>
            ))}
          </DefaultCarousel>
        </BoxCarousel>
      </Content>
    </Container>
  )
}

export default S7_CashbackCelular
