import React from 'react'
const IconWhatsapp = () => {
  return (
    <div>
      <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.226562' y='0.64502' width='28.8372' height='28.8372' rx='12.8165' fill='white' />
        <path d='M5.0332 24.676L6.391 19.7392C5.55314 18.2941 5.11288 16.656 5.11369 14.9763C5.1161 9.72469 9.41001 5.45117 14.6858 5.45117C17.2461 5.45197 19.6494 6.44445 21.4571 8.24518C23.264 10.0459 24.2588 12.4394 24.258 14.9851C24.2556 20.2375 19.9617 24.511 14.6858 24.511C13.0842 24.5102 11.5059 24.1104 10.1078 23.3511L5.0332 24.676ZM10.3428 21.6264C11.6918 22.4235 12.9796 22.9009 14.6826 22.9017C19.0675 22.9017 22.6394 19.3499 22.6419 14.9835C22.6435 10.6082 19.0884 7.06125 14.6891 7.05965C10.301 7.05965 6.73145 10.6114 6.72984 14.9771C6.72904 16.7594 7.2538 18.0939 8.13512 19.4901L7.33107 22.4123L10.3428 21.6264ZM19.5077 17.2496C19.4482 17.1503 19.2888 17.091 19.049 16.9716C18.8099 16.8523 17.634 16.2763 17.4143 16.197C17.1954 16.1177 17.036 16.0777 16.8759 16.3164C16.7165 16.5543 16.2577 17.091 16.1185 17.2496C15.9793 17.4082 15.8392 17.4282 15.6002 17.3089C15.3611 17.1895 14.5901 16.9388 13.6766 16.1273C12.9659 15.4961 12.4854 14.7167 12.3461 14.478C12.2069 14.2401 12.3316 14.1111 12.4508 13.9926C12.5586 13.8861 12.6898 13.7146 12.8097 13.5753C12.9313 13.4375 12.9707 13.3381 13.0512 13.1787C13.1309 13.0201 13.0914 12.8808 13.0311 12.7614C12.9707 12.6429 12.4926 11.4709 12.2938 10.9943C12.099 10.5305 11.9018 10.593 11.7554 10.5858L11.2966 10.5778C11.1372 10.5778 10.8781 10.6371 10.6592 10.8758C10.4402 11.1145 9.8221 11.6896 9.8221 12.8615C9.8221 14.0334 10.6793 15.1653 10.7984 15.3239C10.9183 15.4825 12.4846 17.8872 14.8838 18.9181C15.4545 19.1633 15.9004 19.3099 16.2473 19.4196C16.8203 19.6006 17.3419 19.575 17.754 19.5141C18.2135 19.446 19.1689 18.9382 19.3685 18.3823C19.5681 17.8255 19.5681 17.3489 19.5077 17.2496Z' fill='#20C05D' />
      </svg>
    </div>
  )
}
export default IconWhatsapp
