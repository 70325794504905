import React from 'react'
const IconGas = () => {
  return (
    <div>
      <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd' d='M14.2605 17.4438C14.0125 17.0767 13.5985 16.8568 13.1556 16.8568C12.7127 16.8568 12.2987 17.0767 12.0507 17.4438L10.3574 19.9504C8.82062 22.2255 10.7158 24.8568 13.1556 24.8568C15.5954 24.8568 17.4906 22.2255 15.9538 19.9504L14.2605 17.4438ZM12.5672 21.4431L13.1556 20.5721L13.744 21.4431C13.848 21.5971 13.8439 21.7435 13.7583 21.8864C13.6667 22.0392 13.4687 22.1901 13.1556 22.1901C12.8425 22.1901 12.6445 22.0392 12.5529 21.8864C12.4673 21.7435 12.4632 21.5971 12.5672 21.4431Z' fill='#161616' />
        <path fillRule='evenodd' clipRule='evenodd' d='M4.48893 7.52344C4.48893 5.3143 6.27979 3.52344 8.48893 3.52344H17.8223C20.0314 3.52344 21.8223 5.3143 21.8223 7.52344V22.1901C21.8223 22.9265 22.4192 23.5234 23.1556 23.5234C23.892 23.5234 24.4889 22.9265 24.4889 22.1901V13.6681C24.4889 13.643 24.4892 13.6178 24.4896 13.5928C24.4892 13.5697 24.4889 13.5466 24.4889 13.5234C24.4889 12.9994 24.6099 12.5036 24.8253 12.0625C25.1436 11.3364 25.6756 10.7094 26.3689 10.2761L30.4489 7.72611C31.0734 7.33583 31.896 7.52566 32.2863 8.15011C32.6765 8.77455 32.4867 9.59715 31.8623 9.98744L30.0995 11.0892C30.7494 11.6975 31.1556 12.563 31.1556 13.5234C31.1556 15.3644 29.6632 16.8568 27.8223 16.8568C27.594 16.8568 27.371 16.8338 27.1556 16.7901V22.1901C27.1556 24.3992 25.3647 26.1901 23.1556 26.1901C22.6881 26.1901 22.2393 26.1099 21.8223 25.9625V27.5234H29.8223C30.5586 27.5234 31.1556 28.1204 31.1556 28.8568C31.1556 29.5932 30.5586 30.1901 29.8223 30.1901H3.1556C2.41922 30.1901 1.82227 29.5932 1.82227 28.8568C1.82227 28.1204 2.41922 27.5234 3.1556 27.5234H4.48893V7.52344ZM7.1556 27.5234H19.1556V15.5234H7.1556V27.5234ZM7.1556 12.8568H19.1556V7.52344C19.1556 6.78706 18.5586 6.1901 17.8223 6.1901H8.48893C7.75255 6.1901 7.1556 6.78706 7.1556 7.52344V12.8568ZM27.1583 13.5836C27.1887 13.9236 27.4743 14.1901 27.8223 14.1901C28.1905 14.1901 28.4889 13.8916 28.4889 13.5234C28.4889 13.1552 28.1905 12.8568 27.8223 12.8568C27.5743 12.8568 27.3579 12.9922 27.2431 13.1931C27.1958 13.3169 27.1668 13.4484 27.1583 13.5836Z' fill='#161616' />
      </svg>
    </div>
  )
}
export default IconGas
