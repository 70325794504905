import React, { FC } from "react"
import DefaultCarousel from "src/components/UI/Carousels/DefaultCarousel"
import IconSmartphone from '../../assets/icons/_IconSmartphone'
import { Container, Content, BoxCarousel, Card } from './style'
import IconGift from "../../assets/icons/_IconGift"
import IconPlane from "../../assets/icons/_IconPlane"
import IconGas from "../../assets/icons/_IconGas"
import IconCar from "../../assets/icons/_IconCar"
import IconFork from "../../assets/icons/_IconFork"
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

interface IEconomizeDiaDia {
  icon: React.JSX.Element;
  title: string;
  desc: string;
  link: string;
}

const data: IEconomizeDiaDia[] = [
  {
    icon: <IconSmartphone />,
    title: 'Recarga de Celular',
    desc: 'Recarregue e ganhe GBs extras.',
    link: 'https://inter-co.onelink.me/Qyu7/dn88tc5q',
  },
  {
    icon: <IconGift />,
    title: 'Gift Card',
    desc: 'Economize no Uber, jogos e mais.',
    link: 'https://inter-co.onelink.me/Qyu7/kr6pnvp2',
  },
  {
    icon: <IconPlane />,
    title: 'Passagens e hospedagens',
    desc: 'Ganhe cashback na sua próxima viagem.',
    link: 'https://inter-co.onelink.me/Qyu7/8us1g4ri',
  },
  {
    icon: <IconGas />,
    title: 'ShellBox',
    desc: 'Receba dinheiro de volta ao abastecer.',
    link: 'https://inter-co.onelink.me/Qyu7/i5fjo47d',
  },
  {
    icon: <IconCar />,
    title: 'Tag',
    desc: 'Liberação automática de cancela em pedágios.',
    link: 'https://inter-co.onelink.me/Qyu7/z0p5ijzy',
  },
  {
    icon: <IconFork />,
    title: 'Duo Gourmet',
    desc: '2 pratos pelo preço de 1 + cartão Black.',
    link: 'https://inter-co.onelink.me/Qyu7/ldf17mo5',
  },
]

const S8_EconomizeDiaDia: FC = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Container>
      <Content>
        <h3>Economize ainda mais no seu dia a dia.</h3>
        <BoxCarousel>
          <DefaultCarousel
            sm={{ items: 2 }}
            md={{ items: 3 }}
            lg={{ items: 4 }}
            xl={{ items: 5 }}
          >
            {data?.map((item: IEconomizeDiaDia) => (
              <Card key={item?.title}>
                <div>
                  {item?.icon}
                  <h4>{item?.title}</h4>
                  <p>{item?.desc}</p>
                </div>
                <a
                  title={item?.title}
                  target='_blank'
                  rel='noreferrer'
                  href={item?.link}
                  className='btn btn-orange--extra buttonMD'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Economize ainda mais no seu dia a dia.',
                      element_action: 'click button',
                      element_name: 'Aproveitar',
                      redirect_url: item?.link,
                    })
                  }}
                >
                  Aproveitar
                </a>
              </Card>
            ))}
          </DefaultCarousel>
        </BoxCarousel>
      </Content>
    </Container>
  )
}

export default S8_EconomizeDiaDia
