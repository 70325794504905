import React from 'react'
const IconWaze = () => {
  return (
    <div>
      <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0.472656' y='0.64502' width='28.8372' height='28.8372' rx='12.8165' fill='white' />
        <path d='M15.4641 22.2153C7.25467 22.2153 5.49377 19.9299 4.74171 18.9537C4.57184 18.7441 4.4787 18.4797 4.47852 18.2066C4.47852 17.5568 4.99163 17.0276 5.62285 17.0276C6.01879 17.0276 7.22492 17.0276 7.22492 14.7088V14.4338C7.22492 10.1432 10.9211 6.65234 15.4641 6.65234C20.0071 6.65234 23.7033 10.1432 23.7033 14.4338C23.7033 18.7245 20.0071 22.2153 15.4641 22.2153Z' fill='#37474F' />
        <path d='M15.4627 21.2999C19.5008 21.2999 22.7864 18.2196 22.7864 14.4339C22.7864 10.6481 19.5008 7.56787 15.4627 7.56787C11.4246 7.56787 8.13898 10.6481 8.13898 14.4339V14.7099C8.13898 17.6661 6.37808 17.9853 5.62144 17.9853C5.56075 17.9853 5.50253 18.0102 5.45961 18.0546C5.41669 18.099 5.39258 18.1592 5.39258 18.222C5.39289 18.2774 5.41274 18.3308 5.44842 18.3721C6.12678 19.2562 7.69544 21.2999 15.4627 21.2999Z' fill='#ECEFF1' />
        <path d='M17.2963 13.0607C17.8019 13.0607 18.2118 12.6508 18.2118 12.1452C18.2118 11.6396 17.8019 11.2297 17.2963 11.2297C16.7907 11.2297 16.3809 11.6396 16.3809 12.1452C16.3809 12.6508 16.7907 13.0607 17.2963 13.0607Z' fill='#37474F' />
        <path d='M12.7182 13.0607C13.2238 13.0607 13.6337 12.6508 13.6337 12.1452C13.6337 11.6396 13.2238 11.2297 12.7182 11.2297C12.2126 11.2297 11.8027 11.6396 11.8027 12.1452C11.8027 12.6508 12.2126 13.0607 12.7182 13.0607Z' fill='#37474F' />
        <path d='M18.2096 14.8916C18.2096 14.8916 17.8663 16.7225 15.4632 16.7225C13.0601 16.7225 12.7168 14.8916 12.7168 14.8916' stroke='#37474F' strokeWidth='0.801034' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.4293 24.0463C11.6933 24.0463 12.718 23.0217 12.718 21.7577C12.718 20.4937 11.6933 19.469 10.4293 19.469C9.1653 19.469 8.14062 20.4937 8.14062 21.7577C8.14062 23.0217 9.1653 24.0463 10.4293 24.0463Z' fill='#37474F' />
        <path d='M10.8862 23.1307C11.3918 23.1307 11.8016 22.7209 11.8016 22.2153C11.8016 21.7097 11.3918 21.2998 10.8862 21.2998C10.3806 21.2998 9.9707 21.7097 9.9707 22.2153C9.9707 22.7209 10.3806 23.1307 10.8862 23.1307Z' fill='#607D8B' />
        <path d='M17.7535 24.0463C19.0175 24.0463 20.0422 23.0217 20.0422 21.7577C20.0422 20.4937 19.0175 19.469 17.7535 19.469C16.4895 19.469 15.4648 20.4937 15.4648 21.7577C15.4648 23.0217 16.4895 24.0463 17.7535 24.0463Z' fill='#37474F' />
        <path d='M18.2104 23.1307C18.716 23.1307 19.1259 22.7209 19.1259 22.2153C19.1259 21.7097 18.716 21.2998 18.2104 21.2998C17.7048 21.2998 17.2949 21.7097 17.2949 22.2153C17.2949 22.7209 17.7048 23.1307 18.2104 23.1307Z' fill='#607D8B' />
      </svg>
    </div>
  )
}
export default IconWaze
