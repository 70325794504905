import React, { FC, useState } from 'react'
import { BoxTitle, Container, Content, BoxCards, CarouselContent, CarouselSpace, MobileContent } from './style'
import CardInterCel, { ICardInterCel } from '../../components/CardInterCel/_index'
import ModalCardInterCel from '../../components/ModalCardInterCel/_index'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { structuredData } from '../../pageContext.json'

const S4_CardsInterCel: FC = () => {
  const [ selectCard, setSelectCard ] = useState<ICardInterCel>()

  const handleOpenModal = (dataCard: ICardInterCel) => {
    document.body.style.overflow = 'hidden'
    setSelectCard(dataCard)
  }

  const handleCloseModal = () => {
    document.body.style.overflow = 'auto'
    setSelectCard(undefined)
  }

  const dataPage: ICardInterCel[] = structuredData?.session_4

  return (
    <Container id='session-4'>
      <Content>
        <BoxTitle>
          <h2>Traga seu número para Inter Cel</h2>
          <p>
            Você <strong>mantém o número do seu telefone</strong> e aproveita muito mais vantagens.
            É só escolher abaixo o <strong>melhor plano</strong> pra você e pedir a portabilidade pelo
            nosso Super App quando seu chip chegar
          </p>
        </BoxTitle>
        <BoxCards>
          <CarouselContent>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 3 }}
              lg={{ items: 3 }}
              xl={{ items: 4 }}
              notShowArrows
              notShowDots
              autoPlay
            >
              {dataPage?.map((item: ICardInterCel) => (
                <CarouselSpace key={item?.title}>
                  <CardInterCel
                    data={item}
                    openModal={() => handleOpenModal(item)}
                  />
                </CarouselSpace>
              ))}
            </DefaultCarousel>
          </CarouselContent>

          <MobileContent>
            {dataPage?.map((item: ICardInterCel) => (
              <CardInterCel
                key={item?.title}
                data={item}
                openModal={() => handleOpenModal(item)}
              />
            ))}
          </MobileContent>

          {selectCard && (
            <ModalCardInterCel
              data={selectCard}
              onClose={handleCloseModal}
            />
          )}
        </BoxCards>
      </Content>
    </Container>
  )
}

export default S4_CardsInterCel
