import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const Container = styled.section`
  background-color: #EA7100;
  display: block;
  padding: 40px 64px;

  @media (min-width: ${breakpoints.lg}){
    padding: 64px 0;
  }

  @media (min-width: ${breakpoints.xl}){
    padding: 96px 0;
  }
`

export const Content = styled.div`
  text-align: center;

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 26.4px;
    color: #FFFFFF;
  }

  p {
    font-family: 'inter', Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: #FDF8EE;
  }

  @media (min-width: ${breakpoints.sm}){
    h2{
      font-size: 28px;
      line-height: 30.8px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    h2{
      font-size: 40px;
      line-height: 44px;
    }
    p{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    h2{
      font-size: 72px;
      line-height: 79.2px;
    }
    p{
      font-size: 28px;
      line-height: 33.89px;
    }
  }
`
