import React from 'react'
const IconCheck = () => {
  return (
    <div>
      <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M22.5 12L14.4998 19.9998L10.5 16' stroke='#00AA4F' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </div>
  )
}
export default IconCheck
