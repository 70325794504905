import React from 'react'

const IconFacebook = () => {
  return (
    <div>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12 2.05835C6.75 2.05835 2.5 6.10835 2.5 11.0583C2.5 13.6583 3.65 15.9583 5.5 17.6083V22.0583L9.4 19.7083C10.2 19.9083 11.1 20.0583 12 20.0583C17.25 20.0583 21.5 16.0083 21.5 11.0583C21.5 6.10835 17.25 2.05835 12 2.05835Z' fill='#448AFF' />
        <path d='M6 14.0583L11 8.55835L13.5 11.0583L18 8.55835L13 14.0583L10.5 11.5583L6 14.0583Z' fill='white' />
      </svg>
    </div>
  )
}
export default IconFacebook
