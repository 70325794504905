import React, { useEffect, useState } from "react"
import useWidth from "src/hooks/window/useWidth"
import { initTheme, Theme } from '@interco/inter-ui'
import { CouponText, CouponTitle, QRCodeContainer, SnackBarContainer } from "./style"
import { WIDTH_SM } from "src/styles/breakpoints"
import { Button } from '@interco/inter-ui/components/Button'
import { CollapsibleSnackBar } from "@interco/inter-ui/components/CollapsibleSnackBar"
import Coupon from "@interco/icons/build-v4/orangeds/MD/coupon"
import qrCode from '../../assets/images/qr-code-coupon.png'
import { orange } from "src/styles/colors"

const CouponSnackBar = () => {
  const [ isHidden, setIsHidden ] = useState(false)
  const width = useWidth()

  useEffect(() => {
    initTheme(Theme.PF)
  })

  const SnackBarTitle = (
    <CouponTitle>
      <strong>Você ganhou 1 <strong style={{ color: orange.extra, marginLeft: '3px' }}>CUPOM</strong></strong>
    </CouponTitle>
  )

  const SnackBarContent = () => (
    <>
      <CouponText>
        <span>
          R$20 OFF no 1° mês pra garantir o plano de R$30. Use o cupom
          <strong>NOVO30.</strong>
        </span>
      </CouponText>
      {width <= WIDTH_SM ? (
        <Button
          as='a'
          href='https://intergo.app/3c1d4926'
          fullWidth
          style={{ height: '32px' }}
        >
          Pegar cupom
        </Button>
      ) : (
        <QRCodeContainer>
          <img height={144} src={qrCode} alt='Oferta exclusiva no Super App Inter. Leia o QR Code e seja direcionado.' />
          <p>Oferta exclusiva no Super App Inter.<br />Leia o QR Code e seja direcionado.</p>
        </QRCodeContainer>
      )
      }
    </>
  )

  return !isHidden && (
    <SnackBarContainer>
      <CollapsibleSnackBar
        title={SnackBarTitle}
        ariaLabel='Você ganhou 1 CUPOM'
        icon={<Coupon width={24} height={24} color='#fff' />}
        onCloseListener={() => setIsHidden(true)}
      >
        <SnackBarContent />
      </CollapsibleSnackBar>
    </SnackBarContainer>
  )
}
export default CouponSnackBar
