import React, { useState, useEffect } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import QueryString from 'query-string'
import { useLocation } from '@reach/router'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import IntercelRightForm from 'src/components/UI/Forms/IntercelRightForm'
import pageContext from './pageContext.json'
import { Wrapper } from './style'

import CouponSnackBar from './components/CouponSnackBar/_index'
import S1_Hero from './sections/S1_Hero/_index'
import S2_BannerPlano from './sections/S2_BannerPlano/_index'
import S3_CoberturaVivo from './sections/S3_CoberturaVivo/_index'
import S4_CardsInterCel from './sections/S4_CardsInterCel/_index'
import S5_SempreConectado from './sections/SempreConectado/_index'
import S6_PortabilidadeInterCel from './sections/S6_PortabilidadeInterCel/_index'
import S7_CashbackCelular from './sections/S7_CashbackCelular/_index'
import S8_EconomizeDiaDia from './sections/S8_EconomizeDiaDia/_index'
import S9_DicasSimplificar from './sections/S9_DicasSimplificar/_index'
import S10_FAQ from './sections/S10_FAQ/_index'

const Intercel = () => {
  const [ modalIntercelRightForm, setModalIntercelRightForm ] = useState(false)
  const domReady = useDomReady()
  const location = useLocation()

  const queryString = QueryString.parse(location.href)

  useEffect(() => {
    if (queryString.modal === 'open-intercel-modal') {
      setModalIntercelRightForm(true)
      window.history.pushState('', '', window.location.href.replace('?&modal=open-intercel-modal', ''))
    }
  }, [])

  const OpenAccoutForm = domReady && (
    <Modal isModalOpen={modalIntercelRightForm} setIsModalOpen={setModalIntercelRightForm} locationToRender={document.body}>
      <IntercelRightForm closeModal={() => setModalIntercelRightForm(false)} />
    </Modal>
  )

  return (
    <Wrapper>
      {OpenAccoutForm}
      <Layout pageContext={pageContext}>
        <S1_Hero />
        <S2_BannerPlano />
        <S3_CoberturaVivo />
        <S4_CardsInterCel />
        <S5_SempreConectado />
        <S6_PortabilidadeInterCel />
        <S7_CashbackCelular />
        <S8_EconomizeDiaDia />
        <S9_DicasSimplificar />
        <S10_FAQ />
        <CouponSnackBar />
      </Layout>
    </Wrapper>
  )
}

export default Intercel
